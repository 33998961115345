<template>
  <section>
    <div class="login d-flex flex-column align-items-center">
      <div class="col-md-3 col-sm-12 login-form">
        <div class="login-form-title">
          <h3>Login</h3>
        </div>
        <b-form @submit.prevent="auth">
          <b-alert
            :show="show"
            variant="danger"
            class="d-flex justify-content-center"
          >
            <b>Credenciais inválidas</b>
          </b-alert>
          <div class="login-form-content d-flex flex-column">
            <b-form-group label="Email" label-size="sm" label-for="input-2">
              <b-form-input
                name="email"
                v-model="model.email"
                placeholder="Email"
                type="email"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Senha" label-size="sm" label-for="input-2">
              <b-form-input
                name="senha"
                type="password"
                v-model="model.password"
                placeholder="Senha"
                required
              ></b-form-input>
            </b-form-group>
            <a type="button">Esqueceu a senha?</a>
            <div class="form-input-button text-right mt-3">
              <b-button type="submit" variant="primary">
                <span v-if="!loading">Entrar</span>
                <b-spinner
                  v-else
                  class="mb-1 ml-3 mr-3"
                  variant="spinning"
                  small
                />
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      show: false,
      model: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions(["login"]),
    async auth() {
      this.show = false;
      this.loading = true;
      await this.login(this.model);
      this.loading = false;
      this.$router.push("/painel");
    }
  },
  computed: {
    ...mapGetters(["showError"])
  },
  watch: {
    showError(value) {
      if (value) {
        this.show = value;
        this.$store.commit("showError", { show: false });
      }
    }
  },
  mounted() {
    const user = localStorage.getItem("user-payload");
    if (user != null && user.length > 0) {
      this.$router.push("/painel");
    }
  }
};
</script>
<style scoped>
#user {
  fill: #000;
}
</style>
